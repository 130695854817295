import react,{useState} from "react";
import { useNavigate } from "react-router-dom";


const CustomTabs =({tabs,activeTab})=>{
    const [activeCard, setActiveCard] = useState(activeTab);
    const Navigate = useNavigate();
    const handleActiveCard = (cardName, name) => {
        Navigate(cardName);
        setActiveCard(name);
      };
    
     
    return(
        <div className="container_tabs p-2" style={{ marginTop: "-1%" }}>
           <div className="tabs_wrapper">
            {tabs?.map((item,index)=>{
            return(
                <>
                <input type="radio" name="tabs" id={index} checked={activeCard === item.component} />
                <label
                 className={`tab ${
                 activeCard === item.component ? "active badge-soft-warning" : ""}`}
                  htmlFor={index}
                  onClick={() => handleActiveCard(`${item.route}`, `${item.component}`)}>
                  <span className="material-icons d-lg-block d-none">
                   {item.icon}
                  </span>
                  <span className="title">&nbsp;&nbsp;&nbsp;&nbsp;{item.tabName}</span>
                  </label>
                  </> )})
            }
          </div> 
        </div>
    )
}
export default CustomTabs;
