import React, { useEffect, useState } from "react";

import { Card, CardBody, Col } from "reactstrap";
import { Button, Modal, Table } from "react-bootstrap";
import sbi_logo from "../../../../src/assets/images/sbi_logo.png";
import EditProduct from "./EditProduct";
import AddProduct from "./AddProduct";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useGetProductListQuery, useGetProductbyIdQuery } from "./CommissionApiSlice";
import  bob_img from "../../../assets/images/bob.webp"
import  hdfc_logo from "../../../assets/images/HDFC.webp"


const ProductMaster = () => {
  const [apiData, setApiData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, pageCount: 0 });
  const [editProductModal,  setEditProductModal] = useState(false);
  const [AddProductModel, setAddProductModal] = useState(false);
  const [cardName, setCardName] = useState("card_1");
  const [productCode, setProductCode] = useState("");

  const { data, isLoading, refetch } = useGetProductListQuery();

  
  const { data: productbyId } = useGetProductbyIdQuery(productCode);

  useEffect(() => {
    
    const dataFromApi = data?.data ? data?.data : [];
    // const paginationFromApi = data?.data?.pages ? { page: data?.data?.current_page, pageCount: data?.data?.pages }: pagination;
    setApiData(dataFromApi);
  }, [data, isLoading]);

  const columns = [
    { field: "product_code", headerName: "Product Code", width: 160 },
    { field: "product_category", headerName: "product_category", width: 220 },
    { field: "product_name", headerName: "Product Name", width: 200 },
    {
      field: "leads_commission_percent",
      headerName: "Commission % ",
      width: 150,
    },
    {
      field: "saathi_commission_percent",
      headerName: "Saathi Commission % ",
      width: 180,
    },
    { field: "commission_domain", headerName: "Commission Domain", width: 180 },
    { field: "bank_name", headerName: "Bank Name", width: 150 },
    // { field: 'address', headerName: 'Address', width: 200 },
  ];



  const handleEditProduct = (product_code) => {
    setEditProductModal(true);
    setProductCode(product_code);
    // console.log(product_code , "productCode")
  };
  const handleCloseEditModal = () => {
    setEditProductModal(false);
    setProductCode("");
  };
  const handleAddProduct = () => {
    setAddProductModal(true);
  };
  return (
    <div>
      <Card className="">
        <CardBody
          className="d-flex flex-lg-row flex-md-row flex-column gap-lg-0 gap-md-0 gap-3 justify-content-evenly"
          style={{ padding: "2%" }}
        >
          <div
            className="col-lg-3  "
            onClick={() => setCardName("card_1")}
          >
            <Card
              className={`commission_card ${
                cardName === "card_1" ? "gr-1" : ""
              }  `}
            >
              <CardBody className="p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-lg-4">
                    <img
                      src={sbi_logo}
                      style={{ height: "100px" }}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-8">
                    <h4
                      className={` ${
                        cardName === "card_1" ? "text-white" : ""
                      }  `}
                    >
                      State Bank of India
                    </h4>
                  </div>
                  
                </div>
               
              </CardBody>
            </Card>
          </div>
          <div
            className="col-lg-3"
            onClick={() => setCardName("card_2")}
          >
            <Card
              className={`commission_card ${
                cardName === "card_2" ? "gr-2" : ""
              }  `}
            >
              <CardBody className="p-2">
                <div className="d-flex justify-content-between align-items-center globlboxshadow">
                  <div className="col-lg-4">
                    <img
                      src={bob_img}
                      style={{ height: "100px" }}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-7">
                    <h4
                      className={` ${
                        cardName === "card_2" ? "text-white" : ""
                      }  `}
                    >
                      Bank Of Baroda
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div
            className="col-lg-3  "
            onClick={() => setCardName("card_3")}
          >
            <Card
              className={`commission_card ${
                cardName === "card_3" ? "gr-3" : ""
              }  `}
            >
              <CardBody className="p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="col-lg-4">
                    <img
                      src={hdfc_logo}
                      style={{ height: "100px" }}
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-lg-7">
                    <h4
                      className={` ${
                        cardName === "card_3" ? "text-white" : ""
                      }  `}
                    >
                      HDFC
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

         
        </CardBody>
      </Card>

      <Card>
        <CardBody style={{ padding: "2%" }}>
          <Col
            lg="12"
            className={`d-flex justify-content-${
              cardName === "card_1" ? "between" : "end"
            } align-items-center`}
          >
            {cardName === "card_1" ? (
              <div className="d-flex mx-1 mt-1">
                <h5 className="m-0">Total Products - </h5>
                <h5 className="m-0 text-success">&nbsp; {apiData.length}</h5>
              </div>
            ) : (
              ""
            )}


            <Button size="sm"  onClick={handleAddProduct}><span className="mdi mdi-playlist-plus fs-6"></span>Add Product</Button>
          </Col>
          {cardName === "card_1" ? (
            <Table striped className="mt-2" >
              <thead>
                <tr className="product_table">
                  <th className="th-sm">Product Category</th>
                  <th className="th-sm">Product Name</th>
                  <th className="th-sm">Commission (%)</th>
                  <th className="th-sm">Edit</th>
                </tr>
              </thead>
              <tbody>
                {apiData?.map((item, index) => (
                  <tr key={index} className="mt-3">
                    <td>{item.product_category}</td>
                    <td>{item.product_name}</td>
                    <td> <CurrencyRupeeIcon className="fs-6 text-success" style={{marginTop:"-1%"}} />{item.commission_percent}{" "} </td>
                    <td>
                      {" "}
                      <i
                        className="mdi mdi-text-box-edit-outline fs-5 icon cursor text-primary"
                        name={"view"}
                        onClick={() => handleEditProduct(item.product_code)}
                      />
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="text-center mt-3">
              <h5>No data available</h5>
            </div>
          )}
         
        </CardBody>
      </Card>
      <Modal show={editProductModal} onHide={handleCloseEditModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2.5%" }}>
          <div className="formdetails">
            <EditProduct
              productData={productbyId?.data}
              refetch={refetch}
              ModalState={setEditProductModal}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={AddProductModel}
        onHide={() => setAddProductModal(false)}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2.5%" }}>
          <div className="formdetails">
            <AddProduct refetch={refetch} ModalState={setAddProductModal} cardName={cardName} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProductMaster;
