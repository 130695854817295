import "./App.scss";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "shared/Layout";

import Aggregation from "features/Procurement/Aggregation";
import Trading from "features/Procurement/Trading";
import AddNewAgraniSathi from "features/SaathiManagement/AddNewSaathi/AddNewAgraniSathi";
import Commission from "features/SaathiManagement/Commision/Commission";
import NotFound from "global/NotFound";
import UpdaspLayout from "shared/UpdaspLayout";
// import SaathiList from 'features/SaathiManagement/SaathiList';
const RequireAuth = lazy(() => import("features/auth/RequireAuth"));
const Spinner = lazy(() => import("shared/Spinner"));
const Dashboard = lazy(() => import("features/dashboard/Dashboard"));
const FpoDashAddEmployee = lazy(() =>
  import("features/dashboard/FpoDashAddEmployee")
);
const OnboardingRoutes = lazy(() =>
  import("features/onboarding/common/OnboardingRoutes")
);
const OnboardingLeadsRoutes = lazy(() =>
  import("features/onboarding/common/OnboardingLeadsRoutes")
);
const BankingRoutes = lazy(() =>
  import("features/banking/common/BankingRoutes")
);
const Profile = lazy(() => import("features/Profile/Profile"));
const Insurance = lazy(() => import("features/Insurance/Insurance"));
const CrmDashboard = lazy(() => import("features/dashboard/CrmDashboard"));
const MasterDataRoutes = lazy(() =>
  import("features/masterdata/common/MasterDataRoutes")
);
const FarmerPricingList = lazy(() =>
  import("features/onboarding/users/farmer/FarmerPricingList")
);
const UserManagement = lazy(() =>
  import("features/userManagement/UserManagement")
);
const ViewEmployeeDetails = lazy(() =>
  import("features/userManagement/ViewEmployeeDetails")
);
const EmployeeAttendance = lazy(() =>
  import("features/userManagement/EmployeeAttendance")
);
const ProfileDashboard = lazy(() =>
  import("features/dashboard/OrganizationDashboard/ProfileDashboard")
);
const EmployeesDashboard = lazy(() =>
  import("features/dashboard/EmployeesDashboard/EmployeesDashboard")
);
const EmployeesList = lazy(() =>
  import("features/userManagement/EmployeesList")
);
const EmandiRoutes = lazy(() => import("features/E-Mandi/common/EmandiRoutes"));
const RiskManagementTabView = lazy(() =>
  import("features/dashboard/RiskManagement/RiskManagementTabView")
);
const RiskManagementSurveyDetails = lazy(() =>
  import("features/dashboard/RiskManagement/RiskManagementSurveyDetails")
);
const CrmTabView = lazy(() =>
  import("features/dashboard/CrmModules/CrmTabView")
);
// const Pmfme                 =lazy(()=>import("features/banking/Pmfme/Pmfme"))
const SaathiRoutes = lazy(() =>
  import("features/SaathiManagement/common/SaathiRoutes")
);
const SaathiViewDetails = lazy(() =>
  import("features/SaathiManagement/SaathiViewDetails")
);
const UserAttendanceReport = lazy(() =>
  import("features/userManagement/UserAttendanceReport")
);
const UserManagementAttendenceTracking = lazy(() =>
  import("features/userManagement/UserManagementAttendenceTracking")
);
const UserManagementReportsTab = lazy(() =>
  import("features/userManagement/UserManagementReportsTab")
);

// const Analytics      =lazy(()=>import("components/analytics/Analytics"))
const AnalyticsDashboard = lazy(() =>
  import("components/analytics/AnalyticsDashboard")
);
// const CrmInsurance      =lazy(()=>import("features/dashboard/CrmModules/CrmInsurance"))
const ApplicationDetailSection = lazy(() =>
  import("features/dashboard/ApplicationDetailSection")
);
const DeleteApplication = lazy(() =>
  import("features/dashboard/DeleteApplication")
);
const BuyAndSell = lazy(() => import("features/Procurement/common/BuyAndSell"));
const CropAnalyticsiframe = lazy(() =>
  import("components/CropAnalyticsIframe/CropAnalyticsiframe")
);
const ProjectLulc = lazy(() => import("SeperateDashboard/ProjectLulc"));
const FeedbackForm = lazy(() => import("components/Feedback/FeedbackForm"));
const ApprovedApplication = lazy(() =>
  import("features/dashboard/BankerLogin/ApprovedApplication")
);
const ApplicationAction = lazy(() =>
  import("features/dashboard/BankerLogin/ApplicationAction")
);
const SampleDashboard = lazy(() =>
  import("components/analytics/SampleDashboard")
);
const ManufactureContainer = lazy(() =>
  import("features/onboarding/Output/Manufacturer/ManufactureContainer")
);
const ManufacturerSummary = lazy(() =>
  import("features/onboarding/Output/Manufacturer/ManufacturerSummary")
);
// const TraderSummary      =lazy(()=>import("features/onboarding/Output/Manufacturer/TraderSummary"))
const AdminApproval = lazy(() =>
  import("features/Procurement/common/AdminApproval")
);
const ProcurementDashboard = lazy(() =>
  import("features/Procurement/common/ProcurementDashboard")
);
const YieldDashboard = lazy(() => import("SeperateDashboard/YieldDashboard"));
// const DashboardLayoutcheck      =lazy(()=>import("SeperateDashboard/DashboardLayoutcheck"))
const UpDashboard = lazy(() => import("SeperateDashboard/DashboardLayout"));
const ManufacturerLeadsDetails = lazy(() =>
  import("features/onboarding/Output/Manufacturer/ManufacturerLeadDetails")
);
const ProfileProc = lazy(() =>
  import("features/Procurement/common/ProfileProc")
);
const CustomTabs = lazy(() => import("components/CustomTabs"));
const OnBoardingForm = lazy(() =>
  import("features/Procurement/OnBoardingForm")
);
const LoginOnboardingForm = lazy(() =>
  import("components/analytics/LoginOnboardingForm")
);
const RetailersDashboard = lazy(() =>
  import("features/dashboard/RetailersDashboard")
);
const ProcurementAllOngoingTrades = lazy(() =>
  import("features/MobileProcurement/ProcurementAllOngoingTrades")
);
const ProcSellerOngoingDetails = lazy(() =>
  import("features/MobileProcurement/ProcSellerOngoingDetail")
);
const ProcBuyerInitiatedAllTrades = lazy(() =>
  import("features/MobileProcurement/ProcBuyerInitiatedAllTrades")
);
const ManageStocksAll = lazy(() =>
  import("features/MobileProcurement/ManageStocksAll")
);
const BuyerOngoingTradesAll = lazy(() =>
  import("features/masterdata/common/MobileProcurementBuyer/BuyerOngoingTradesAll")
);
const BuyerOngoingDetails = lazy(() =>
  import("features/masterdata/common/MobileProcurementBuyer/BuyerOngoingDetails")
);
const InitiatedByFarmersAll = lazy(() =>
  import("features/masterdata/common/MobileProcurementBuyer/InitiatedByFarmersAll")
);
const EmandiPriceTrends = lazy(() => import("features/E-Mandi/EmandiPriceTrends"));


const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {/* Public Routes */}
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/UpDashboard" element={<DashboardLayout/>} ></Route> */}
        <Route path="/onboard" element={<LoginOnboardingForm />}></Route>
        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          <Route path="/" element={<Layout />}>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/CrmDashboard" element={<CrmDashboard />} />
            <Route exact path="/profile" element={<ProfileDashboard />} />
            <Route exact path="/FpoDashAddEmployee/:id">
              <Route index element={<FpoDashAddEmployee />}></Route>
            </Route>
            <Route
              exact
              path="/onboarding/leads/*"
              element={<OnboardingLeadsRoutes />}
            />
            <Route exact path="/onboarding/*" element={<OnboardingRoutes />} />
            <Route exact path="/pricing" element={<FarmerPricingList />} />
            {/* <Route exact path="/banking/*" element={<BankingRoutes />} /> */}
            <Route exact path="/banking/*" element={<BankingRoutes />} />
            <Route exact path="/user_profile" element={<Profile />}></Route>
            <Route
              exact
              path="/dashboard"
              element={<EmployeesDashboard />}
            ></Route>
            <Route exact path="/insurance" element={<Insurance />}></Route>
            <Route
              exact
              path="/masterdata/*"
              element={<MasterDataRoutes />}
            ></Route>
            <Route path="/user_management">
              <Route index element={<UserManagement />} />
              <Route path=":id" element={<ViewEmployeeDetails />} />
              <Route path="saathi/:id" element={<SaathiViewDetails />} />
            </Route>
            <Route
              path="/user_management/attendance"
              element={<EmployeeAttendance />}
            />
            <Route
              path="/user_management/reports"
              element={<UserManagementReportsTab />}
            />
            <Route
              path="/user_management/Employee/attendance"
              element={<UserManagementAttendenceTracking />}
            />
            <Route exact path="employees" element={<EmployeesList />}></Route>
            <Route
              exact
              path="employees/:id"
              element={<EmployeesList />}
            ></Route>
            <Route path="/e-mandi/*" element={<EmandiRoutes />}></Route>
            <Route path="/priceTrends" element={<EmandiPriceTrends />}></Route>

            <Route
              path="/user_managemant/tracking/:id"
              element={<UserAttendanceReport />}
            />
            <Route path="/riskManagement/*">
              <Route index element={<RiskManagementTabView />} />
              <Route
                path="surveydetails"
                element={<RiskManagementSurveyDetails />}
              />
            </Route>
            <Route path="/crm" element={<CrmTabView />}></Route>
            <Route
              path="/crm/application/:id"
              element={<ApplicationDetailSection />}
            ></Route>
            <Route
              path="/saathi_management/*"
              element={<SaathiRoutes />}
            ></Route>
            <Route path="/aggregation" element={<Aggregation />}></Route>
            <Route path="/trading" element={<Trading />}></Route>
            <Route
              path="/banking/analytics/*"
              element={<AnalyticsDashboard />}
            ></Route>
            <Route path="/delete/*" element={<DeleteApplication />}></Route>
            <Route path="/BuyAndSell" element={<BuyAndSell />}></Route>
            <Route
              path="/cropanalytics"
              element={<CropAnalyticsiframe />}
            ></Route>
            <Route path="/ProjectDashboard" element={<ProjectLulc />}></Route>
            <Route path="/feedback" element={<FeedbackForm />}></Route>
            <Route path="/bankingLos" element={<ApprovedApplication />}></Route>
            <Route
              path="/bankingLos/:id"
              element={<ApplicationAction />}
            ></Route>
            <Route
              path="/dashboardLayout"
              element={<SampleDashboard />}
            ></Route>
            <Route path="/traders" element={<ManufactureContainer />} />
            {/* <Route  path="/outputs/Manufacturers/:id" element={<ManufacturerSummary />}></Route> */}
            <Route
              path="/outputs/:organization_type/:id"
              element={<ManufacturerSummary />}
            ></Route>
            <Route
              path="/onboarding/outputs/:organization_type/leads/:id"
              element={<ManufacturerLeadsDetails />}
            ></Route>
            <Route path="/adminApproval" element={<AdminApproval />} />
            <Route path="/YieldDashboard" element={<YieldDashboard />} />
            <Route
              path="/ProcurementDashboard"
              element={<ProcurementDashboard />}
            />
             <Route
              path="/ProcurementDashboard/ongoingTrades"
              element={<ProcurementAllOngoingTrades />}
            />
               <Route
              path="/ProcurementDashboard/ongoingTradess"
              element={<ProcBuyerInitiatedAllTrades />}
            />
              <Route
              path="/ProcurementDashboard/ongoingTrades/details"
              element={<ProcSellerOngoingDetails />}
            />
             <Route
              path="/ProcurementDashboard/manageStock"
              element={<ManageStocksAll />}
            />
              <Route
              path="/ProcurementDashboard/buyer/ongoingTrades"
              element={<BuyerOngoingTradesAll />}
            />
               <Route
              path="/ProcurementDashboard/buyer/ongoingTrades/details"
              element={<BuyerOngoingDetails />}
            />
             <Route
              path="/ProcurementDashboard/buyer/FarmerTrades"
              element={<InitiatedByFarmersAll />}
            />
            <Route path="/agrani_saathi" element={<AddNewAgraniSathi />} />
            <Route path="/banking/commission" element={<Commission />} />
            <Route path="/masterdata/commission" element={<Commission />} />
            {/* <Route exact path="/DashboardLayoutcheck" element={<DashboardLayoutcheck />}></Route> */}
            <Route
              exact
              path="/completeProfile/:id"
              element={<ProfileProc />}
            ></Route>
            <Route path="/custom*" element={<CustomTabs />}></Route>
            <Route path="/retailers*" element={<RetailersDashboard />}></Route>

            
          </Route>
        {/* <Route path="/updasp" element={<UpdaspLayout />}> */}
          {/* <Route path='/updasp' element={<div><iframe style={{width: "100%", height: "100vh"}} src="https://updasp.agrani.io" title="Agrani | Updasp" /></div>} /> */}
          {/* </Route> */}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
