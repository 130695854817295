import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search"; // Import search icon

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    // padding: "8px 26px 8px 12px",
    padding: "8px 40px 8px 12px",

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    [theme.breakpoints.down('sm')]: {
      // padding: "10px 27px 8px 11px", 
      padding: "8px 40px 8px 12px",

    },

    // Focused state
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));


const CustomSearchBar = ({ sendSearchFunction }) => {
  return (
    <div>
      <FormControl
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          width:"100%"
        }}
        variant="standard"
        onChange={(e) => sendSearchFunction(e.target.value)}
      >
        <BootstrapInput placeholder="Search ..."/>
        <SearchIcon
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            color: "#ced4da",
            pointerEvents: "none",
          }}
        />

      </FormControl>
    </div>
  );
};

export default CustomSearchBar;
