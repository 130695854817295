import { useEffect } from "react";

const UpdaspLayout = () => {
  useEffect(() => {
    window.history.pushState({}, undefined, window.location.pathname)
    window.location.href = "https://updasp.agrani.io"
  }, []);
  return
};

export default UpdaspLayout;
